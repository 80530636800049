@tailwind base;
@tailwind components;
@tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Mr+Dafoe&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
   
}

body {
  margin: 0;
font-family: 'Montserrat', sans-serif;
/* font-family: 'Mr Dafoe', cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background-color: fff;

}
