.navbar-container {
  padding: 0px 50px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(4px);
  background-color: #fff;
}

/* Logo container and text */
.logo-container {
  display: flex;
  align-items: center;
  width: 70px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-left: 30px;

  color: #0666ba;
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 0.4rem;
  font-family: "Montserrat", sans-serif;
}
.nav-link:hover {
  cursor: pointer;
  color: red;
}

.project-active {
  color: #0666ba;
}
/* Navigation Donation BTN */
.nav-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  color: #0666ba;
  padding: 15px 30px;
  border-radius: 25px;
  line-height: 22px;
  transition: all 0.6s;
}
.nav-btn:hover {
  cursor: pointer;
  background-color: #e6ffe0;
  color: #0666ba;
}

.active {
  color: #0666ba;
}
.projects-container,
.about-container,
.resources-container {
  padding: 20px;
  position: fixed;
  z-index: 50;
  top: 77px;
  width: 240px;
  box-shadow: 0px 12px 16px -4px rgba(53, 53, 53, 0.05);
  border-radius: 8px;
  background-color: #fff;
}
.projects-container {
  right: 30%;
}
.about-container {
  right: 40%;
}
.resources-container {
  right: 20%;
}
.donate-projects-container {
  position: fixed;
  z-index: 50;
  left: 60%;
  top: 77px;
  width: 240px;
  box-shadow: 0px 12px 16px -4px rgba(53, 53, 53, 0.05);
  border-radius: 8px;
  background-color: #f3fff3;
}
.project-list {
  list-style: none;
  font-family: "M", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #30020b;
  margin-bottom: 20px;
}
.project-list:hover{
  color: #0666ba;
}

@media screen and (max-width: 480px) {
  .logo-container {
    width: 60px;
  }
  .nav-links {
    display: none;
  }
  .nav-sub-link {
    list-style: none;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
    color: white;
  }

  .nav-btn {
    display: none;
  }
  .navbar-container {
    background-color: white;
    padding: 0px 20px;
    width: 100%;
    position: fixed;
    height: 68px;
    backdrop-filter: blur(4px);
    
  }
  .navbar-dropDown {
    background-color: #131446;
    height: 100%;
    width: 100%;
    z-index: 60;
    padding: 0px 20px;
    top: 68px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-nav-links {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 50px;
    
  }
  .nav-link {
    margin-left: 0;
    margin-bottom: 20px;
    color: white;
  }
  .nav-link:hover {
    border-bottom: none;
  }
  .mobile-nav-btn {
    font-style: normal;
    font-weight: 600;
    background-color: #5aee55;
    color: white;
    padding: 15px 30px;
    border-radius: 25px;
    border: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-top: 20px;
  }
  .socials-container {
    margin-top: 40px;
    display: flex;
  }
  .social {
    margin-right: 15px;
  }
  .mobile-navbar-text {
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
 color: white;
  }

  .projects-container,
  .about-container,
  .resources-container {
    display: none;
  }
}
@media screen and (min-width: 480px) {
  .hamburger-btn {
    display: none;
  }
  .navbar-dropDown {
    display: none;
  }
}

@media only screen and (max-width: 820px) and (min-width: 481px) {
  .navbar-container {
    padding: 0px 20px;
  }
  .nav-link {
    margin-left: 10px;
    font-size: 14px;
  }
  .nav-btn {
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    align-self: flex-end;
  }
  .logo-container {
    width: 120px;
  }
}
